import React, { useState } from 'react';
import './photos.css';

interface PhotoGridProps {
  honeymoonImages: string[];
  weddingImages: string[];
}

export const PhotoGrid: React.FC<PhotoGridProps> = ({ honeymoonImages, weddingImages }) => {
  const [modalImage, setModalImage] = useState<string | null>(null);

  const openModal = (src: string) => {
    setModalImage(src);
  };

  const closeModal = () => {
    setModalImage(null);
  };

  return (
    <div>
      <div className="photos-grid">
        {weddingImages.map((src, index) => (
          <img key={index} src={src} alt={`Wedding ${index + 1}`} onClick={() => openModal(src)} className="zoomable" />
        ))}
        {honeymoonImages.map((src, index) => (
          <img key={index + weddingImages.length} src={src} alt={`Honeymoon ${index + 1}`} onClick={() => openModal(src)} className="zoomable" />
        ))}
      </div>

      {modalImage && (
        <div className="modal" onClick={closeModal}>
          <span className="close" onClick={closeModal}>&times;</span>
          <div className="modal-content">
            <img src={modalImage} alt="Zoomed" className="zoomed" />
          </div>
        </div>
      )}
    </div>
  );
};

export default PhotoGrid;