import React, { useState } from 'react';
import honeymoon1 from "../../pictures/HoneyMoon/honeyMoon (1).jpg";
import honeymoon2 from "../../pictures/HoneyMoon/honeyMoon (2).jpg";
import honeymoon3 from "../../pictures/HoneyMoon/honeyMoon (3).jpg";
import honeymoon4 from "../../pictures/HoneyMoon/honeyMoon (4).jpg";
import honeymoon5 from "../../pictures/HoneyMoon/honeyMoon (5).jpg";
import honeymoon6 from "../../pictures/HoneyMoon/honeyMoon (6).jpg";
import honeymoon7 from "../../pictures/HoneyMoon/honeyMoon (7).jpg";
import honeymoon8 from "../../pictures/HoneyMoon/honeyMoon (8).jpg";
import honeymoon9 from "../../pictures/HoneyMoon/honeyMoon (9).jpg";
import honeymoon10 from "../../pictures/HoneyMoon/honeyMoon (10).jpg";
import honeymoon11 from "../../pictures/HoneyMoon/honeyMoon (11).jpg";
import honeymoon12 from "../../pictures/HoneyMoon/honeyMoon (12).jpg";
import honeymoon13 from "../../pictures/HoneyMoon/honeyMoon (13).jpg";
import honeymoon14 from "../../pictures/HoneyMoon/honeyMoon (14).jpg";
import honeymoon15 from "../../pictures/HoneyMoon/honeyMoon (15).jpg";
import honeymoon16 from "../../pictures/HoneyMoon/honeyMoon (16).jpg";
import honeymoon17 from "../../pictures/HoneyMoon/honeyMoon (17).jpg";
import honeymoon18 from "../../pictures/HoneyMoon/honeyMoon (18).jpg";
import honeymoon19 from "../../pictures/HoneyMoon/honeyMoon (19).jpg";
import honeymoon20 from "../../pictures/HoneyMoon/honeyMoon (20).jpg";
import honeymoon21 from "../../pictures/HoneyMoon/honeyMoon (21).jpg";
import honeymoon22 from "../../pictures/HoneyMoon/honeyMoon (22).jpg";
import honeymoon23 from "../../pictures/HoneyMoon/honeyMoon (23).jpg";
import honeymoon24 from "../../pictures/HoneyMoon/honeyMoon (24).jpg";
import honeymoon25 from "../../pictures/HoneyMoon/honeyMoon (25).jpg";
import honeymoon26 from "../../pictures/HoneyMoon/honeyMoon (26).jpg";
import honeymoon27 from "../../pictures/HoneyMoon/honeyMoon (27).jpg";
import honeymoon28 from "../../pictures/HoneyMoon/honeyMoon (28).jpg";
import honeymoon29 from "../../pictures/HoneyMoon/honeyMoon (29).jpg";
import honeymoon30 from "../../pictures/HoneyMoon/honeyMoon (30).jpg";
import honeymoon31 from "../../pictures/HoneyMoon/honeyMoon (31).jpg";
import honeymoon32 from "../../pictures/HoneyMoon/honeyMoon (32).jpg";
import honeymoon33 from "../../pictures/HoneyMoon/honeyMoon (33).jpg";
import honeymoon34 from "../../pictures/HoneyMoon/honeyMoon (34).jpg";
import honeymoon35 from "../../pictures/HoneyMoon/honeyMoon (35).jpg";
import honeymoon36 from "../../pictures/HoneyMoon/honeyMoon (36).jpg";
import honeymoon37 from "../../pictures/HoneyMoon/honeyMoon (37).jpg";
import honeymoon38 from "../../pictures/HoneyMoon/honeyMoon (38).jpg";
import honeymoon39 from "../../pictures/HoneyMoon/honeyMoon (39).jpg";
import honeymoon40 from "../../pictures/HoneyMoon/honeyMoon (40).jpg";
import honeymoon41 from "../../pictures/HoneyMoon/honeyMoon (41).jpg";
import honeymoon42 from "../../pictures/HoneyMoon/honeyMoon (42).jpg";
import honeymoon43 from "../../pictures/HoneyMoon/honeyMoon (43).jpg";
import honeymoon44 from "../../pictures/HoneyMoon/honeyMoon (44).jpg";
import honeymoon45 from "../../pictures/HoneyMoon/honeyMoon (45).jpg";
import honeymoon46 from "../../pictures/HoneyMoon/honeyMoon (46).jpg";
import honeymoon47 from "../../pictures/HoneyMoon/honeyMoon (47).jpg";
import honeymoon48 from "../../pictures/HoneyMoon/honeyMoon (48).jpg";
import honeymoon49 from "../../pictures/HoneyMoon/honeyMoon (49).jpg";
import honeymoon50 from "../../pictures/HoneyMoon/honeyMoon (50).jpg";
import honeymoon51 from "../../pictures/HoneyMoon/honeyMoon (51).jpg";
import honeymoon52 from "../../pictures/HoneyMoon/honeyMoon (52).jpg";
import honeymoon53 from "../../pictures/HoneyMoon/honeyMoon (53).jpg";
import honeymoon54 from "../../pictures/HoneyMoon/honeyMoon (54).jpg";
import honeymoon55 from "../../pictures/HoneyMoon/honeyMoon (55).jpg";
import honeymoon56 from "../../pictures/HoneyMoon/honeyMoon (56).jpg";
import honeymoon57 from "../../pictures/HoneyMoon/honeyMoon (57).jpg";
import honeymoon58 from "../../pictures/HoneyMoon/honeyMoon (58).jpg";
import honeymoon59 from "../../pictures/HoneyMoon/honeyMoon (59).jpg";
import honeymoon60 from "../../pictures/HoneyMoon/honeyMoon (60).jpg";
import honeymoon61 from "../../pictures/HoneyMoon/honeyMoon (61).jpg";
import wedding1 from "../../pictures/Wedding/wedding (1).jpg";
import wedding2 from "../../pictures/Wedding/wedding (2).jpg";
import wedding3 from "../../pictures/Wedding/wedding (3).jpg";
import wedding4 from "../../pictures/Wedding/wedding (4).jpg";
import wedding5 from "../../pictures/Wedding/wedding (5).jpg";
import wedding6 from "../../pictures/Wedding/wedding (6).jpg";
import wedding7 from "../../pictures/Wedding/wedding (7).jpg";
import wedding8 from "../../pictures/Wedding/wedding (8).jpg";
import wedding9 from "../../pictures/Wedding/wedding (9).jpg";
import wedding10 from "../../pictures/Wedding/wedding (10).jpg";
import wedding11 from "../../pictures/Wedding/wedding (11).jpg";
import wedding12 from "../../pictures/Wedding/wedding (12).jpg";
import wedding13 from "../../pictures/Wedding/wedding (13).jpg";
import wedding14 from "../../pictures/Wedding/wedding (14).jpg";
import wedding15 from "../../pictures/Wedding/wedding (15).jpg";
import wedding16 from "../../pictures/Wedding/wedding (16).jpg";
import wedding17 from "../../pictures/Wedding/wedding (17).jpg";
import wedding18 from "../../pictures/Wedding/wedding (18).jpg";
import wedding19 from "../../pictures/Wedding/wedding (19).jpg";
import wedding20 from "../../pictures/Wedding/wedding (20).jpg";
import wedding21 from "../../pictures/Wedding/wedding (21).jpg";
import wedding22 from "../../pictures/Wedding/wedding (22).jpg";
import wedding23 from "../../pictures/Wedding/wedding (23).jpg";
import wedding24 from "../../pictures/Wedding/wedding (24).jpg";
import wedding25 from "../../pictures/Wedding/wedding (25).jpg";
import wedding26 from "../../pictures/Wedding/wedding (26).jpg";
import wedding27 from "../../pictures/Wedding/wedding (27).jpg";
import wedding28 from "../../pictures/Wedding/wedding (28).jpg";
import wedding29 from "../../pictures/Wedding/wedding (29).jpg";
import wedding30 from "../../pictures/Wedding/wedding (30).jpg";
import wedding31 from "../../pictures/Wedding/wedding (31).jpg";
import wedding32 from "../../pictures/Wedding/wedding (32).jpg";
import wedding33 from "../../pictures/Wedding/wedding (33).jpg";
import wedding34 from "../../pictures/Wedding/wedding (34).jpg";
import wedding35 from "../../pictures/Wedding/wedding (35).jpg";
import wedding36 from "../../pictures/Wedding/wedding (36).jpg";
import wedding37 from "../../pictures/Wedding/wedding (37).jpg";
import wedding38 from "../../pictures/Wedding/wedding (38).jpg";
import wedding39 from "../../pictures/Wedding/wedding (39).jpg";
import wedding40 from "../../pictures/Wedding/wedding (40).jpg";
import wedding41 from "../../pictures/Wedding/wedding (41).jpg";
import wedding42 from "../../pictures/Wedding/wedding (42).jpg";
import wedding43 from "../../pictures/Wedding/wedding (43).jpg";
import wedding44 from "../../pictures/Wedding/wedding (44).jpg";
import wedding45 from "../../pictures/Wedding/wedding (45).jpg";
import wedding46 from "../../pictures/Wedding/wedding (46).jpg";
import wedding47 from "../../pictures/Wedding/wedding (47).jpg";
import wedding48 from "../../pictures/Wedding/wedding (48).jpg";
import wedding49 from "../../pictures/Wedding/wedding (49).jpg";
import wedding50 from "../../pictures/Wedding/wedding (50).jpg";
import wedding51 from "../../pictures/Wedding/wedding (51).jpg";
import wedding52 from "../../pictures/Wedding/wedding (52).jpg";
import wedding53 from "../../pictures/Wedding/wedding (53).jpg";
import wedding54 from "../../pictures/Wedding/wedding (54).jpg";
import wedding55 from "../../pictures/Wedding/wedding (55).jpg";
import wedding56 from "../../pictures/Wedding/wedding (56).jpg";
import wedding57 from "../../pictures/Wedding/wedding (57).jpg";
import wedding58 from "../../pictures/Wedding/wedding (58).jpg";
import wedding59 from "../../pictures/Wedding/wedding (59).jpg";
import wedding60 from "../../pictures/Wedding/wedding (60).jpg";
import wedding61 from "../../pictures/Wedding/wedding (61).jpg";
import wedding62 from "../../pictures/Wedding/wedding (62).jpg";
import wedding63 from "../../pictures/Wedding/wedding (63).jpg";
import wedding64 from "../../pictures/Wedding/wedding (64).jpg";
import wedding65 from "../../pictures/Wedding/wedding (65).jpg";
import wedding66 from "../../pictures/Wedding/wedding (66).jpg";
import wedding67 from "../../pictures/Wedding/wedding (67).jpg";
import wedding68 from "../../pictures/Wedding/wedding (68).jpg";
import wedding69 from "../../pictures/Wedding/wedding (69).jpg";
import wedding70 from "../../pictures/Wedding/wedding (70).jpg";
import wedding71 from "../../pictures/Wedding/wedding (71).jpg";
import wedding72 from "../../pictures/Wedding/wedding (72).jpg";
import wedding73 from "../../pictures/Wedding/wedding (73).jpg";
import wedding74 from "../../pictures/Wedding/wedding (74).jpg";
import wedding75 from "../../pictures/Wedding/wedding (75).jpg";
import wedding76 from "../../pictures/Wedding/wedding (76).jpg";
import wedding77 from "../../pictures/Wedding/wedding (77).jpg";
import wedding78 from "../../pictures/Wedding/wedding (78).jpg";
import wedding79 from "../../pictures/Wedding/wedding (79).jpg";
import wedding80 from "../../pictures/Wedding/wedding (80).jpg";
import wedding81 from "../../pictures/Wedding/wedding (81).jpg";
import wedding82 from "../../pictures/Wedding/wedding (82).jpg";
import wedding83 from "../../pictures/Wedding/wedding (83).jpg";
import wedding84 from "../../pictures/Wedding/wedding (84).jpg";
import wedding85 from "../../pictures/Wedding/wedding (85).jpg";
import wedding86 from "../../pictures/Wedding/wedding (86).jpg";
import wedding87 from "../../pictures/Wedding/wedding (87).jpg";
import wedding88 from "../../pictures/Wedding/wedding (88).jpg";
import wedding89 from "../../pictures/Wedding/wedding (89).jpg";
import wedding90 from "../../pictures/Wedding/wedding (90).jpg";
import wedding91 from "../../pictures/Wedding/wedding (91).jpg";
import wedding92 from "../../pictures/Wedding/wedding (92).jpg";
import wedding93 from "../../pictures/Wedding/wedding (93).jpg";
import wedding94 from "../../pictures/Wedding/wedding (94).jpg";
import wedding95 from "../../pictures/Wedding/wedding (95).jpg";
import wedding96 from "../../pictures/Wedding/wedding (96).jpg";
import wedding97 from "../../pictures/Wedding/wedding (97).jpg";
import wedding98 from "../../pictures/Wedding/wedding (98).jpg";
import wedding99 from "../../pictures/Wedding/wedding (99).jpg";
import wedding100 from "../../pictures/Wedding/wedding (100).jpg";
import wedding101 from "../../pictures/Wedding/wedding (101).jpg";
import wedding102 from "../../pictures/Wedding/wedding (102).jpg";
import wedding103 from "../../pictures/Wedding/wedding (103).jpg";
import wedding104 from "../../pictures/Wedding/wedding (104).jpg";
import wedding105 from "../../pictures/Wedding/wedding (105).jpg";
import wedding106 from "../../pictures/Wedding/wedding (106).jpg";
import wedding107 from "../../pictures/Wedding/wedding (107).jpg";
import wedding108 from "../../pictures/Wedding/wedding (108).jpg";
import wedding109 from "../../pictures/Wedding/wedding (109).jpg";
import wedding110 from "../../pictures/Wedding/wedding (110).jpg";
import wedding111 from "../../pictures/Wedding/wedding (111).jpg";
import wedding112 from "../../pictures/Wedding/wedding (112).jpg";
import wedding113 from "../../pictures/Wedding/wedding (113).jpg";
import wedding114 from "../../pictures/Wedding/wedding (114).jpg";
import wedding115 from "../../pictures/Wedding/wedding (115).jpg";
import wedding116 from "../../pictures/Wedding/wedding (116).jpg";
import wedding117 from "../../pictures/Wedding/wedding (117).jpg";
import wedding118 from "../../pictures/Wedding/wedding (118).jpg";
import wedding119 from "../../pictures/Wedding/wedding (119).jpg";
import wedding120 from "../../pictures/Wedding/wedding (120).jpg";
import wedding121 from "../../pictures/Wedding/wedding (121).jpg";
import wedding122 from "../../pictures/Wedding/wedding (122).jpg";
import wedding123 from "../../pictures/Wedding/wedding (123).jpg";
import wedding124 from "../../pictures/Wedding/wedding (124).jpg";
import wedding125 from "../../pictures/Wedding/wedding (125).jpg";
import wedding126 from "../../pictures/Wedding/wedding (126).jpg";
import "./photos.css";
import PhotoGrid from './photoGrid';

const Photos = () => {
  const honeymoonImages = [
    honeymoon1, honeymoon2, honeymoon3, honeymoon4, honeymoon5, honeymoon6, honeymoon7, honeymoon8, honeymoon9, honeymoon10,
    honeymoon11, honeymoon12, honeymoon13, honeymoon14, honeymoon15, honeymoon16, honeymoon17, honeymoon18, honeymoon19, honeymoon20,
    honeymoon21, honeymoon22, honeymoon23, honeymoon24, honeymoon25, honeymoon26, honeymoon27, honeymoon28, honeymoon29, honeymoon30,
    honeymoon31, honeymoon32, honeymoon33, honeymoon34, honeymoon35, honeymoon36, honeymoon37, honeymoon38, honeymoon39, honeymoon40,
    honeymoon41, honeymoon42, honeymoon43, honeymoon44, honeymoon45, honeymoon46, honeymoon47, honeymoon48, honeymoon49, honeymoon50,
    honeymoon51, honeymoon52, honeymoon53, honeymoon54, honeymoon55, honeymoon56, honeymoon57, honeymoon58, honeymoon59, honeymoon60,
    honeymoon61
  ];

  const weddingImages = [
    wedding1, wedding2, wedding3, wedding4, wedding5, wedding6, wedding7, wedding8, wedding9, wedding10,
    wedding11, wedding12, wedding13, wedding14, wedding15, wedding16, wedding17, wedding18, wedding19, wedding20,
    wedding21, wedding22, wedding23, wedding24, wedding25, wedding26, wedding27, wedding28, wedding29, wedding30,
    wedding31, wedding32, wedding33, wedding34, wedding35, wedding36, wedding37, wedding38, wedding39, wedding40,
    wedding41, wedding42, wedding43, wedding44, wedding45, wedding46, wedding47, wedding48, wedding49, wedding50,
    wedding51, wedding52, wedding53, wedding54, wedding55, wedding56, wedding57, wedding58, wedding59, wedding60,
    wedding61, wedding62, wedding63, wedding64, wedding65, wedding66, wedding67, wedding68, wedding69, wedding70,
    wedding71, wedding72, wedding73, wedding74, wedding75, wedding76, wedding77, wedding78, wedding79, wedding80,
    wedding81, wedding82, wedding83, wedding84, wedding85, wedding86, wedding87, wedding88, wedding89, wedding90,
    wedding91, wedding92, wedding93, wedding94, wedding95, wedding96, wedding97, wedding98, wedding99, wedding100,
    wedding101, wedding102, wedding103, wedding104, wedding105, wedding106, wedding107, wedding108, wedding109, wedding110,
    wedding111, wedding112, wedding113, wedding114, wedding115, wedding116, wedding117, wedding118, wedding119, wedding120,
    wedding121, wedding122, wedding123, wedding124, wedding125, wedding126
  ];

  return <PhotoGrid honeymoonImages={honeymoonImages} weddingImages={weddingImages} ></PhotoGrid>
};

export default Photos;